section{
  padding: 30px 10px;

  @include breakpoint(medium){
    padding: 70px 40px 0;
  }

  @include breakpoint(large){
    padding: 160px 60px 0;
  }

  @include breakpoint(xlarge){
    padding: 190px 60px 0;
    margin-left: -50px;
  }

  /**/
  /* HOME */
  /**/

  &#home{

    .intro{
      position: relative;
      padding: 40px 40px 40px 40px;
      background: url('/img/MustangStudio-intro-achtergrond.jpg') center center no-repeat;
      background-size: cover;
      margin-bottom: 20px;

      @include breakpoint(medium){
        padding: 30px 30px 30px 30px;
        margin-bottom: 30px;
      }

      @include breakpoint(xlarge){
        padding: 17px 17px 17px 37px;
        margin-bottom: 50px;
        width: 925px;
        min-height: 512px;
        height: auto;
        margin-right: -35px;
      }

      p{
        color:white;
        font-family: Avenir, 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        margin-bottom: 24px;

        @include breakpoint(large){
          font-size: 1.35rem;
          line-height: 2rem;
        }
      }
    }

    a{
      &.mustang-button{
        @include breakpoint(large) {
          margin-left: 87px;
        }
      }
    }

    .keywords{
      p{
        margin-left: 50px;
        margin-bottom: 24px;
        font-size: 1.2rem;
        font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

        @include breakpoint(large){
          font-size: 1.4rem;
          margin-left: 50px;
        }

        @include breakpoint(xlarge){
          width: 120px;
          font-size: 1.7rem;
        }
      }
    }

    #arthur{
      width: 95px;
      height: 113px;
      overflow: hidden;
      background: url('/img/MustangStudio-intro-ArthurKoster.jpg') center center no-repeat;
      background-size: cover;
      float: right;

      &:before {
        content: '' ;
        display: block;
        float: right;
        width: 95px;
        height: 113px;
      }
    }

  }

  &#sections{
    padding-bottom: 80px;

    .kleur, .zwart-wit, .specials {
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;
      background-size: cover;
      padding: 100px 0;

      @include breakpoint(medium){
        height: 300px;
      }

      @include breakpoint(large){
        height: 420px;
      }
      @include breakpoint(xlarge){
        width: 360px;
        height: 612px;
      }

      h1 {
        position: absolute;
        top: 0;
        left: 15px;
        color: white;
        margin-bottom: 0;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
        font-family: 'Frutiger', 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold;

        @include breakpoint(large){
          font-size: 4rem;
        }
      }
    }

    /* ACHTERGRONDEN */
    .kleur{
      background: url('/img/section/MustangStudio-Kleur.jpg') center center no-repeat;
    }
    .zwart-wit{
      background: url('/img/section/MustangStudio-ZwartWit.jpg') 80% center no-repeat;
    }
    .specials{
      background: url('/img/section/MustangStudio-Specials.jpg') center center no-repeat;
    }

    .button{

      margin-top: 50px;
    }
  }

  /**/
  /* PROJECTS IN SPECIALS */
  /**/

  &#kleur, &#zwartwit{

    .projects{

      ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
          position: relative;
          padding: 17px 0;
          font-size: 1.1rem;

          .number{
            font-weight: 200;
            position: absolute;
            right: 0;
            background-color: #666;
            color: white;
            text-align: center;
            border-radius: 1px;
            padding: 2px 6px;

            @include breakpoint(medium){
              margin-right: 40px;
            }
          }

          a{
            color:#333;
            display: block;
            font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

            transition: all .1s ease-out;

            &:hover{
              padding-left: 5px;
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  /**/
  /* PROJECTS IN SPECIALS */
  /**/

  &#specials{

    .projects{

      ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
          position: relative;
          padding: 17px 0;
          font-size: 1.1rem;

          .number{
            font-weight: 200;
            position: absolute;
            right: 0;
            background-color: #666;
            color: white;
            text-align: center;
            border-radius: 1px;
            padding: 2px 6px;

            @include breakpoint(medium){
              margin-right: 40px;
            }

            @include breakpoint(xlarge){
              margin-right: -10px;
            }
          }

          a{
            color:#333;
            display: block;
            font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

            transition: all .1s ease-out;

            &:hover{
              padding-left: 5px;
              color: $primary-color;
            }
          }
        }
      }
    }
  }

  /**/
  /* SPECIALS FOTO */
  /**/

  &#specials{

    #overview-foto{
      position: relative;
      margin-top: 15px;
      border-radius: $global-radius;

      @include breakpoint(xlarge) {
        margin-left: 20px;
      }

        &.specials{

          @include breakpoint(xlarge){
            padding: 2px 0 0 0;
            width: 1020px;
            height: 530px;
            overflow: hidden;
          }
        }

      h1{
        position: absolute;
        bottom: -6px;
        right: 10px;
        font-weight: bold;
        color: white;

        @include breakpoint(xlarge){
          font-size: 4rem;
          margin-bottom: 0;
        }
      }
    }

    a {
      &.mustang-button {
        @include breakpoint(large) {
          margin-left: 65px;
        }
      }
    }
  }

  /**/
  /* KLEUR & ZWARTWIT FOTO */
  /**/

  &#kleur, &#zwartwit{

    #overview-foto{
      position: relative;
      margin-top: 15px;
      border-radius: $global-radius;

      @include breakpoint(xlarge) {
        margin-left: 20px;
      }

      &.kleur, &.zwartwit{

        @include breakpoint(xlarge){
          margin: 17px 0 0 -20px;
          padding: 0;
          height: 529px;
          width: 792px;
          overflow: hidden;
        }
      }

      h1 {
        position: absolute;
        top: -10px;
        right: 10px;
        font-weight: bold;
        color: white;

        @include breakpoint(xlarge){
          font-size: 4rem;
          margin-bottom: 0;
        }
      }
    }

      a {
        &.mustang-button {
          @include breakpoint(large) {
            margin-left: 30px;
          }
        }
      }
    }
  }