/* HTML */
* {
  margin: 0;
}

html, body {
  height: 100%;
}

body{
  position: relative;
  min-height: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.mustang-background-top{
  position: absolute;
  top: -2px;
  z-index: 10;
}

.mustang-background-right{
  height: 100%;
  display: none;
  position: absolute;
  right: 0;
  padding: 0;
  width: 100px;
  z-index: 10;

  @include breakpoint(large){
    display: block;
  }

  img{
    height: 100%;
    position: absolute;
    right: 0;
  }
}

.mustang-background-bottom{
  position: absolute;
  bottom: 0;
  z-index: 10;

  @include breakpoint(large){
    border-bottom: 30px solid #a3d5de;
  }

  @include breakpoint(xlarge){
    border-bottom: 3px solid #a3d5de;
  }
}

.mustang-background-left{
  height: 100%;
  display: none;
  position: absolute;
  left: 0;
  padding: 0;
  width: 100px;
  z-index: 10;

  @include breakpoint(large){
    display: block;
  }

  img{
    height: 100%;
    position: absolute;
    left: 0;
  }
}

.push {
  height: 100px;
}


#mustangstudio{
  min-height: 100%;
  height: auto !important;
  height: 100%;
  width: auto;
  overflow: auto;
  margin: 0 auto -100px; /* the bottom margin is the negative value of the footer's height */
  overflow-x: hidden;

  @include breakpoint(large){
    width: 100%;
    height: 100%;
  }

  .mustang-background{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -12;
  }

  .header-page{
    font-family: "BernhardMod BT", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    margin-bottom: 25px;

    @include breakpoint(large) {
      padding-left: 72px;
    }
  }
  #project{

    .header-page{

      @include breakpoint(large) {
        padding-left: 0;
      }
    }
  }
  #kleur, #zwartwit{

    .header-page{

      @include breakpoint(large) {
        padding-left: 21px;
      }
    }
  }
  #specials{

    .header-page{

      @include breakpoint(large) {
        padding-left: 56px;
      }
    }
  }
}


/* LOGO */
#logo{
  width: 100%;
  padding: 20px 50px 10px 10px;
  z-index: 4;
  margin-bottom: 40px;

  @include breakpoint(medium){
    width: 100%;
    padding: 20px 120px 10px 80px;
  }

  @include breakpoint(large){
    padding: 0;
    width: 375px;
    position: absolute;
    bottom: 112px;
    right: 67px;
    margin-bottom: 0;
  }
}

/**/
/* CONTACT IN FOOTER */
/**/

#contact{
  text-align: center;
  margin: 0 auto;

  @include breakpoint(large){
    position: absolute;
    z-index: 15;
    bottom: 20px;
    right: 20px;
  }

  .contact-phone, .contact-email{
    display: inline-block;
  }

  #contact-phone, #contact-email{
    width: 35px;
    height: 35px;
    display: inline-block;
    margin-right: 5px;
    background-color: #84d7e9;
    border-radius: 50px;
    padding: 5px;
  }

  p{
    font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    display: inline-block;
    font-size: .8rem;
    text-transform: uppercase;
    margin-right: 30px;

    a{
      color:#333;
      border-bottom: 1px dashed transparent;

      transition: all .2s ease-out;

      &:hover{
        color: #2083b7;
        border-bottom: 1px dashed #2c789a;
      }
    }
  }
}


/* MISC */

a{
  &.mustang-button{
    width:140px;
    background-color: #b1b3b4;
    font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.35rem;
    padding: 6px;
    text-align: left;

    &:hover{
      background-color: #c2c4c5;
    }

    @include breakpoint(xlarge){
      margin-left: 80px;
    }
  }
}


button.radius, .button.radius {
  border-radius: 3px;
}

button.round, .button.round {
  border-radius: 1000px;
}