#project{

  .project-keywords{

    p{
      display: inline-block;
      border: 1px solid $primary-color;
      padding: 3px 6px;
      border-radius: 2px;
      font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

      @include breakpoint(medium){
        border: 0;
        display: block;
      }

      @include breakpoint(large){
        padding: 5px 0;
        font-size: 1.2rem;
      }

      @include breakpoint(large){
        padding: 5px 0;
        font-size: 1.3rem;
      }

      &:first-of-type{
        border: 0;
      }

      &:last-of-type{
        border: 0;
        display: block;
      }
    }
  }

  a{
    &.mustang-button{
      @include breakpoint(large) {
        margin-left: 10px;
      }
    }
  }

  .button{
    margin-bottom: 20px;
  }

  #fotos{
    
    p{
      font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
      font-size: 1.2rem;
      
    }


    .row{
      .small-12{
        text-align: center;
        @include breakpoint(large){
          text-align: initial;
        }
      }
    }
    
    img{
      margin-bottom: 40px;
    }
  }
}