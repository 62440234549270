@charset "UTF-8";
/*
$global-width: rem-calc(1200);
*/
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url("../../font/Avenir-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "BernhardMod BT";
  src: local("BernhardMod BT"), url("../../font/BernhardModernBT-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Frutiger";
  src: local("Frutiger"), url("../../font/Frutiger-BlockCn.otf") format("opentype");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: "Andale Mono";
  src: local("Andale"), url("../../font/Andale-mono.ttf") format("opentype");
  font-style: normal;
  font-weight: normal; }

/* TOP LEVEL */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 81.25rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 75em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media screen and (min-width: 90em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xlarge-1 {
    width: 8.33333%; }
  .xlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    width: 16.66667%; }
  .xlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    width: 25%; }
  .xlarge-push-3 {
    position: relative;
    left: 25%; }
  .xlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    width: 33.33333%; }
  .xlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    width: 41.66667%; }
  .xlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    width: 50%; }
  .xlarge-push-6 {
    position: relative;
    left: 50%; }
  .xlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    width: 58.33333%; }
  .xlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    width: 66.66667%; }
  .xlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    width: 75%; }
  .xlarge-push-9 {
    position: relative;
    left: 75%; }
  .xlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    width: 83.33333%; }
  .xlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    width: 91.66667%; }
  .xlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
      float: left; }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
      float: left; }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
      float: left; }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
      float: left; }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
      float: left; }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
      float: left; }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
      float: left; }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
      float: left; }
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xlarge-uncentered,
  .xlarge-push-0,
  .xlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media screen and (min-width: 90em) {
  .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-push-1 {
    position: relative;
    left: 8.33333%; }
  .xxlarge-pull-1 {
    position: relative;
    left: -8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-push-2 {
    position: relative;
    left: 16.66667%; }
  .xxlarge-pull-2 {
    position: relative;
    left: -16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    width: 25%; }
  .xxlarge-push-3 {
    position: relative;
    left: 25%; }
  .xxlarge-pull-3 {
    position: relative;
    left: -25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-push-4 {
    position: relative;
    left: 33.33333%; }
  .xxlarge-pull-4 {
    position: relative;
    left: -33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-push-5 {
    position: relative;
    left: 41.66667%; }
  .xxlarge-pull-5 {
    position: relative;
    left: -41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    width: 50%; }
  .xxlarge-push-6 {
    position: relative;
    left: 50%; }
  .xxlarge-pull-6 {
    position: relative;
    left: -50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-push-7 {
    position: relative;
    left: 58.33333%; }
  .xxlarge-pull-7 {
    position: relative;
    left: -58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-push-8 {
    position: relative;
    left: 66.66667%; }
  .xxlarge-pull-8 {
    position: relative;
    left: -66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    width: 75%; }
  .xxlarge-push-9 {
    position: relative;
    left: 75%; }
  .xxlarge-pull-9 {
    position: relative;
    left: -75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-push-10 {
    position: relative;
    left: 83.33333%; }
  .xxlarge-pull-10 {
    position: relative;
    left: -83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-push-11 {
    position: relative;
    left: 91.66667%; }
  .xxlarge-pull-11 {
    position: relative;
    left: -91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%; }
    .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
      float: left; }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%; }
    .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
      float: left; }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
      float: left; }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%; }
    .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
      float: left; }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%; }
    .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
      float: left; }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
      float: left; }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
      float: left; }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
      float: left; }
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto; }
    .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .xxlarge-uncentered,
  .xxlarge-push-0,
  .xxlarge-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #009fe3;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #0089c3; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 81.25rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #0a0a0a;
  color: #0a0a0a;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #009fe3;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #0087c1;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #009fe3;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #007fb6;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #009fe3;
    color: #0a0a0a; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #007fb6;
      color: #0a0a0a; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #ec5840;
    color: #0a0a0a; }
    .button.alert:hover, .button.alert:focus {
      background-color: #da3116;
      color: #0a0a0a; }
  .button.info {
    background-color: #89b3ff;
    color: #0a0a0a; }
    .button.info:hover, .button.info:focus {
      background-color: #3b81ff;
      color: #0a0a0a; }
  .button.hollow {
    border: 1px solid #009fe3;
    color: #009fe3; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #005072;
      color: #005072; }
    .button.hollow.primary {
      border: 1px solid #009fe3;
      color: #009fe3; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #005072;
        color: #005072; }
    .button.hollow.secondary {
      border: 1px solid #009fe3;
      color: #009fe3; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #005072;
        color: #005072; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #ec5840;
      color: #ec5840; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #881f0e;
        color: #881f0e; }
    .button.hollow.info {
      border: 1px solid #89b3ff;
      color: #89b3ff; }
      .button.hollow.info:hover, .button.hollow.info:focus {
        border-color: #0046c4;
        color: #0046c4; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #009fe3;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #009fe3;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #009fe3;
        color: #0a0a0a; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #ec5840;
        color: #0a0a0a; }
    .button.disabled.info, .button[disabled].info {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.info, .button.disabled.info:hover, .button.disabled.info:focus, .button[disabled].info, .button[disabled].info:hover, .button[disabled].info:focus {
        background-color: #89b3ff;
        color: #0a0a0a; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #009fe3; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #009fe3; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #009fe3; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #ec5840; }
  .button.dropdown.hollow.info::after {
    border-top-color: #89b3ff; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 90em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 89.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

/* Preload images */
body:after {
  content: url("/img/lightbox/close.png") url("/img/lightbox/loading.gif") url("/img/lightbox/prev.png") url("/img/lightbox/next.png");
  display: none; }

body.lb-disable-scrolling {
  overflow: hidden; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-container {
  padding: 4px; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/img/lightbox/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/img/lightbox/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/img/lightbox/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url("/img/lightbox/close.png") top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

/* custom*/
/* HTML */
* {
  margin: 0; }

html, body {
  height: 100%; }

body {
  position: relative;
  min-height: 100%;
  height: auto;
  margin: 0;
  padding: 0; }

.mustang-background-top {
  position: absolute;
  top: -2px;
  z-index: 10; }

.mustang-background-right {
  height: 100%;
  display: none;
  position: absolute;
  right: 0;
  padding: 0;
  width: 100px;
  z-index: 10; }
  @media print, screen and (min-width: 64em) {
    .mustang-background-right {
      display: block; } }
  .mustang-background-right img {
    height: 100%;
    position: absolute;
    right: 0; }

.mustang-background-bottom {
  position: absolute;
  bottom: 0;
  z-index: 10; }
  @media print, screen and (min-width: 64em) {
    .mustang-background-bottom {
      border-bottom: 30px solid #a3d5de; } }
  @media screen and (min-width: 75em) {
    .mustang-background-bottom {
      border-bottom: 3px solid #a3d5de; } }

.mustang-background-left {
  height: 100%;
  display: none;
  position: absolute;
  left: 0;
  padding: 0;
  width: 100px;
  z-index: 10; }
  @media print, screen and (min-width: 64em) {
    .mustang-background-left {
      display: block; } }
  .mustang-background-left img {
    height: 100%;
    position: absolute;
    left: 0; }

.push {
  height: 100px; }

#mustangstudio {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  width: auto;
  overflow: auto;
  margin: 0 auto -100px;
  /* the bottom margin is the negative value of the footer's height */
  overflow-x: hidden; }
  @media print, screen and (min-width: 64em) {
    #mustangstudio {
      width: 100%;
      height: 100%; } }
  #mustangstudio .mustang-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -12; }
  #mustangstudio .header-page {
    font-family: "BernhardMod BT", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    margin-bottom: 25px; }
    @media print, screen and (min-width: 64em) {
      #mustangstudio .header-page {
        padding-left: 72px; } }
  @media print, screen and (min-width: 64em) {
    #mustangstudio #project .header-page {
      padding-left: 0; } }
  @media print, screen and (min-width: 64em) {
    #mustangstudio #kleur .header-page, #mustangstudio #zwartwit .header-page {
      padding-left: 21px; } }
  @media print, screen and (min-width: 64em) {
    #mustangstudio #specials .header-page {
      padding-left: 56px; } }

/* LOGO */
#logo {
  width: 100%;
  padding: 20px 50px 10px 10px;
  z-index: 4;
  margin-bottom: 40px; }
  @media print, screen and (min-width: 40em) {
    #logo {
      width: 100%;
      padding: 20px 120px 10px 80px; } }
  @media print, screen and (min-width: 64em) {
    #logo {
      padding: 0;
      width: 375px;
      position: absolute;
      bottom: 112px;
      right: 67px;
      margin-bottom: 0; } }

/**/
/* CONTACT IN FOOTER */
/**/
#contact {
  text-align: center;
  margin: 0 auto; }
  @media print, screen and (min-width: 64em) {
    #contact {
      position: absolute;
      z-index: 15;
      bottom: 20px;
      right: 20px; } }
  #contact .contact-phone, #contact .contact-email {
    display: inline-block; }
  #contact #contact-phone, #contact #contact-email {
    width: 35px;
    height: 35px;
    display: inline-block;
    margin-right: 5px;
    background-color: #84d7e9;
    border-radius: 50px;
    padding: 5px; }
  #contact p {
    font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    display: inline-block;
    font-size: .8rem;
    text-transform: uppercase;
    margin-right: 30px; }
    #contact p a {
      color: #333;
      border-bottom: 1px dashed transparent;
      transition: all .2s ease-out; }
      #contact p a:hover {
        color: #2083b7;
        border-bottom: 1px dashed #2c789a; }

/* MISC */
a.mustang-button {
  width: 140px;
  background-color: #b1b3b4;
  font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.35rem;
  padding: 6px;
  text-align: left; }
  a.mustang-button:hover {
    background-color: #c2c4c5; }
  @media screen and (min-width: 75em) {
    a.mustang-button {
      margin-left: 80px; } }

button.radius, .button.radius {
  border-radius: 3px; }

button.round, .button.round {
  border-radius: 1000px; }

section {
  padding: 30px 10px;
  /**/
  /* HOME */
  /**/
  /**/
  /* PROJECTS IN SPECIALS */
  /**/
  /**/
  /* PROJECTS IN SPECIALS */
  /**/
  /**/
  /* SPECIALS FOTO */
  /**/
  /**/
  /* KLEUR & ZWARTWIT FOTO */
  /**/ }
  @media print, screen and (min-width: 40em) {
    section {
      padding: 70px 40px 0; } }
  @media print, screen and (min-width: 64em) {
    section {
      padding: 160px 60px 0; } }
  @media screen and (min-width: 75em) {
    section {
      padding: 190px 60px 0;
      margin-left: -50px; } }
  section#home .intro {
    position: relative;
    padding: 40px 40px 40px 40px;
    background: url("/img/MustangStudio-intro-achtergrond.jpg") center center no-repeat;
    background-size: cover;
    margin-bottom: 20px; }
    @media print, screen and (min-width: 40em) {
      section#home .intro {
        padding: 30px 30px 30px 30px;
        margin-bottom: 30px; } }
    @media screen and (min-width: 75em) {
      section#home .intro {
        padding: 17px 17px 17px 37px;
        margin-bottom: 50px;
        width: 925px;
        min-height: 512px;
        height: auto;
        margin-right: -35px; } }
    section#home .intro p {
      color: white;
      font-family: Avenir, 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
      margin-bottom: 24px; }
      @media print, screen and (min-width: 64em) {
        section#home .intro p {
          font-size: 1.35rem;
          line-height: 2rem; } }
  @media print, screen and (min-width: 64em) {
    section#home a.mustang-button {
      margin-left: 87px; } }
  section#home .keywords p {
    margin-left: 50px;
    margin-bottom: 24px;
    font-size: 1.2rem;
    font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif; }
    @media print, screen and (min-width: 64em) {
      section#home .keywords p {
        font-size: 1.4rem;
        margin-left: 50px; } }
    @media screen and (min-width: 75em) {
      section#home .keywords p {
        width: 120px;
        font-size: 1.7rem; } }
  section#home #arthur {
    width: 95px;
    height: 113px;
    overflow: hidden;
    background: url("/img/MustangStudio-intro-ArthurKoster.jpg") center center no-repeat;
    background-size: cover;
    float: right; }
    section#home #arthur:before {
      content: '';
      display: block;
      float: right;
      width: 95px;
      height: 113px; }
  section#sections {
    padding-bottom: 80px;
    /* ACHTERGRONDEN */ }
    section#sections .kleur, section#sections .zwart-wit, section#sections .specials {
      position: relative;
      margin-bottom: 20px;
      overflow: hidden;
      background-size: cover;
      padding: 100px 0; }
      @media print, screen and (min-width: 40em) {
        section#sections .kleur, section#sections .zwart-wit, section#sections .specials {
          height: 300px; } }
      @media print, screen and (min-width: 64em) {
        section#sections .kleur, section#sections .zwart-wit, section#sections .specials {
          height: 420px; } }
      @media screen and (min-width: 75em) {
        section#sections .kleur, section#sections .zwart-wit, section#sections .specials {
          width: 360px;
          height: 612px; } }
      section#sections .kleur h1, section#sections .zwart-wit h1, section#sections .specials h1 {
        position: absolute;
        top: 0;
        left: 15px;
        color: white;
        margin-bottom: 0;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
        font-family: 'Frutiger', 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        font-weight: bold; }
        @media print, screen and (min-width: 64em) {
          section#sections .kleur h1, section#sections .zwart-wit h1, section#sections .specials h1 {
            font-size: 4rem; } }
    section#sections .kleur {
      background: url("/img/section/MustangStudio-Kleur.jpg") center center no-repeat; }
    section#sections .zwart-wit {
      background: url("/img/section/MustangStudio-ZwartWit.jpg") 80% center no-repeat; }
    section#sections .specials {
      background: url("/img/section/MustangStudio-Specials.jpg") center center no-repeat; }
    section#sections .button {
      margin-top: 50px; }
  section#kleur .projects ul, section#zwartwit .projects ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    section#kleur .projects ul li, section#zwartwit .projects ul li {
      position: relative;
      padding: 17px 0;
      font-size: 1.1rem; }
      section#kleur .projects ul li .number, section#zwartwit .projects ul li .number {
        font-weight: 200;
        position: absolute;
        right: 0;
        background-color: #666;
        color: white;
        text-align: center;
        border-radius: 1px;
        padding: 2px 6px; }
        @media print, screen and (min-width: 40em) {
          section#kleur .projects ul li .number, section#zwartwit .projects ul li .number {
            margin-right: 40px; } }
      section#kleur .projects ul li a, section#zwartwit .projects ul li a {
        color: #333;
        display: block;
        font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        transition: all .1s ease-out; }
        section#kleur .projects ul li a:hover, section#zwartwit .projects ul li a:hover {
          padding-left: 5px;
          color: #009fe3; }
  section#specials .projects ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    section#specials .projects ul li {
      position: relative;
      padding: 17px 0;
      font-size: 1.1rem; }
      section#specials .projects ul li .number {
        font-weight: 200;
        position: absolute;
        right: 0;
        background-color: #666;
        color: white;
        text-align: center;
        border-radius: 1px;
        padding: 2px 6px; }
        @media print, screen and (min-width: 40em) {
          section#specials .projects ul li .number {
            margin-right: 40px; } }
        @media screen and (min-width: 75em) {
          section#specials .projects ul li .number {
            margin-right: -10px; } }
      section#specials .projects ul li a {
        color: #333;
        display: block;
        font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
        transition: all .1s ease-out; }
        section#specials .projects ul li a:hover {
          padding-left: 5px;
          color: #009fe3; }
  section#specials #overview-foto {
    position: relative;
    margin-top: 15px;
    border-radius: 0; }
    @media screen and (min-width: 75em) {
      section#specials #overview-foto {
        margin-left: 20px; } }
    @media screen and (min-width: 75em) {
      section#specials #overview-foto.specials {
        padding: 2px 0 0 0;
        width: 1020px;
        height: 530px;
        overflow: hidden; } }
    section#specials #overview-foto h1 {
      position: absolute;
      bottom: -6px;
      right: 10px;
      font-weight: bold;
      color: white; }
      @media screen and (min-width: 75em) {
        section#specials #overview-foto h1 {
          font-size: 4rem;
          margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    section#specials a.mustang-button {
      margin-left: 65px; } }
  section#kleur #overview-foto, section#zwartwit #overview-foto {
    position: relative;
    margin-top: 15px;
    border-radius: 0; }
    @media screen and (min-width: 75em) {
      section#kleur #overview-foto, section#zwartwit #overview-foto {
        margin-left: 20px; } }
    @media screen and (min-width: 75em) {
      section#kleur #overview-foto.kleur, section#kleur #overview-foto.zwartwit, section#zwartwit #overview-foto.kleur, section#zwartwit #overview-foto.zwartwit {
        margin: 17px 0 0 -20px;
        padding: 0;
        height: 529px;
        width: 792px;
        overflow: hidden; } }
    section#kleur #overview-foto h1, section#zwartwit #overview-foto h1 {
      position: absolute;
      top: -10px;
      right: 10px;
      font-weight: bold;
      color: white; }
      @media screen and (min-width: 75em) {
        section#kleur #overview-foto h1, section#zwartwit #overview-foto h1 {
          font-size: 4rem;
          margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    section#kleur a.mustang-button, section#zwartwit a.mustang-button {
      margin-left: 30px; } }

#project .project-keywords p {
  display: inline-block;
  border: 1px solid #009fe3;
  padding: 3px 6px;
  border-radius: 2px;
  font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif; }
  @media print, screen and (min-width: 40em) {
    #project .project-keywords p {
      border: 0;
      display: block; } }
  @media print, screen and (min-width: 64em) {
    #project .project-keywords p {
      padding: 5px 0;
      font-size: 1.2rem; } }
  @media print, screen and (min-width: 64em) {
    #project .project-keywords p {
      padding: 5px 0;
      font-size: 1.3rem; } }
  #project .project-keywords p:first-of-type {
    border: 0; }
  #project .project-keywords p:last-of-type {
    border: 0;
    display: block; }

@media print, screen and (min-width: 64em) {
  #project a.mustang-button {
    margin-left: 10px; } }

#project .button {
  margin-bottom: 20px; }

#project #fotos p {
  font-family: "Andale Mono", 'Open Sans', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-size: 1.2rem; }

#project #fotos .row .small-12 {
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    #project #fotos .row .small-12 {
      text-align: initial; } }

#project #fotos img {
  margin-bottom: 40px; }
